import ApiService from "./ApiService";
import ApiFdService from "./ApiFdService";

export default {
    getAllStyles(query) {
        return ApiService.get(`/api/v1/styles?${query}`);
    },
    getStyle(id) {
        return ApiService.get(`/api/v1/styles/${id}`)
    },
    getStyles() {
        return ApiService.get(`/api/v1/styles`)
    },
    addStyle(data) {
        return ApiFdService.post('/api/v1/styles', data);
    },
    updateStyle(data, id) {
        return ApiFdService.post(`/api/v1/styles/${id}`, data);
    },
    removeStyle(id) {
        return ApiService.delete(`/api/v1/styles/${id}`)
    }
}