<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import SidenavItem from "./SidenavItem.vue";

const store = useStore();
const isRTL = computed(() => store.state.General.isRTL);

const getRoute = () => {
  const route = useRoute();
  const routeArr = route.path.split("/");
  return routeArr[1];
};
</script>
<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item
          to="/dashboard"
          :class="getRoute() === 'dashboard' ? 'active' : ''"
          :navText="isRTL ? 'لوحة القيادة' : 'Dashboard'"
        >
          <template v-slot:icon>
            <i class="ni ni-tv-2 text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/styles"
          :class="getRoute() === 'styles' ? 'active' : ''"
          :navText="isRTL ? 'الجداول' : 'Styles'"
        >
          <template v-slot:icon>
            <i
              class="ni ni-palette text-success text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/avatars/request"
          :class="getRoute() === 'avatars/request' ? 'active' : ''"
          :navText="isRTL ? 'الفواتیر' : 'Legacy Request'"
        >
          <template v-slot:icon>
            <i class="ni ni-satisfied text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/avatars"
          :class="getRoute() === 'avatars/' ? 'active' : ''"
          :navText="isRTL ? 'الفواتیر' : 'All Legacies'"
        >
          <template v-slot:icon>
            <i class="ni ni-satisfied text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/legacies"
          :class="getRoute() === 'legacies' ? 'active' : ''"
          :navText="isRTL ? 'الجداول' : 'Legacy Video'"
        >
          <template v-slot:icon>
            <i
              class="ni ni-single-copy-04 text-success text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/legacy-request"
          :class="getRoute() === 'legacy-request' ? 'active' : ''"
          :navText="isRTL ? 'الجداول' : 'Legacy Video Request'"
        >
          <template v-slot:icon>
            <i
              class="ni ni-single-copy-04 text-success text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/users"
          :class="getRoute() === 'users' ? 'active' : ''"
          :navText="isRTL ? 'المستخدمين' : 'Users'"
        >
          <template v-slot:icon>
            <i
              class="ni ni-user-run text-success text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          to="/subscribers"
          :class="getRoute() === 'subscribers' ? 'active' : ''"
          :navText="isRTL ? 'مشتركين' : 'Subscribers'"
        >
          <template v-slot:icon>
            <i
              class="ni ni-credit-card text-success text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>

      <!-- <li class="nav-item">
        <sidenav-item
          to="/legacies"
          :class="getRoute() === 'legacies' ? 'active' : ''"
          :navText="isRTL ? 'الجداول' : 'Legacy Video Request'"
        >
          <template v-slot:icon>
            <i
              class="ni ni-single-copy-04 text-success text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li> -->

      <!-- <li class="nav-item">
        <sidenav-item
          to="/virtual-reality"
          :class="getRoute() === 'virtual-reality' ? 'active' : ''"
          :navText="isRTL ? 'الواقع الافتراضي' : 'Media'"
        >
          <template v-slot:icon>
            <i class="ni ni-image text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/rtl-page"
          :class="getRoute() === 'rtl-page' ? 'active' : ''"
          navText="Transactions"
        >
          <template v-slot:icon>
            <i class="ni ni-world-2 text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li> -->

      <li class="mt-3 nav-item">
        <h6
          v-if="isRTL"
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
          صفحات المرافق
        </h6>

        <h6
          v-else
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
          ACCOUNT PAGES
        </h6>
      </li>

      <!-- <li class="nav-item">
        <sidenav-item
          to="/profile"
          :class="getRoute() === 'profile' ? 'active' : ''"
          :navText="isRTL ? 'حساب تعريفي' : 'Profile'"
        >
          <template v-slot:icon>
            <i class="ni ni-single-02 text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li> -->

      <!-- <li class="nav-item">
        <sidenav-item
          to="/signin"
          :class="getRoute() === 'signin' ? 'active' : ''"
          :navText="isRTL ? 'تسجيل الدخول' : 'Settings'"
        >
          <template v-slot:icon>
            <i class="ni ni-settings-gear-65 text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li> -->

      <li class="nav-item">
        <sidenav-item
          to="/logout"
          :class="getRoute() === 'signup' ? 'active' : ''"
          :navText="isRTL ? 'اشتراك' : 'Log out'"
        >
          <template v-slot:icon>
            <i class="ni ni-curved-next text-danger text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
    </ul>
  </div>

  
</template>
