<script setup>
import ArgonButton from "@/components/ArgonButton.vue";
import AvatarApi from "@/services/Avatar";
import { ref, onMounted } from "vue";
import Swal from 'sweetalert2';

const allAvatars = ref([]);

const loadAvatar = (query) => {
    AvatarApi.getAvatar(query).then((res) => {
    allAvatars.value = res.data.data;
  })
}

const removeAvatar = (name,id) => {
  Swal.fire({
    icon: 'question',
    title: `Remove avatar`,
    text: `Are you sure to remove the "${name}" avatar? This action cannot be undone!`,
    confirmButtonText: "Remove",
    showCancelButton: true,
    cancelButtonText: "Cancel",
  }).then((res)=> {
    if (res.isConfirmed) {
        AvatarApi.removeAvatar(id).then(()=>{
        Swal.fire({
          title: "Nice!",
          icon: "success",
          text: "Your avatar has been successfully removed!"
        });
        loadAvatar();
      })
    }
  })
}

const updateStatus = async (user_id) => {
    const inputOptions = new Promise((resolve) => {
        resolve({
            'pending': "On progress",
            'released': "Ready"
        });
    });
    const { value: status } = await Swal.fire({
        title: "Select status",
        input: "radio",
        inputOptions,
        inputValidator: (value) => {
            if (!value) {
            return "Please select 1 status.";
            }
        }
    });
    if (status) {
        const fd = new FormData();
        fd.append("user_id", user_id);
        fd.append("avatar_status", status);
        fd.append("reason", "");
        AvatarApi.updateAvatarStatus(fd).then(() => {
          Swal.fire({
            title: "Nice!",
            icon: "success",
            text: "Your avatar status has been successfully updated!"
          });
        });
    }
}

onMounted(() => {
  loadAvatar();
})
</script>
<template>
    <div class="card">
      <div class="card-header w-100 d-flex align-items-center justify-content-between pb-0 mb-4">
        <h6>All Legacies</h6>
        <div class="d-flex">
            <router-link to="/avatars/request">
                <argon-button
                    color="info"
                    variant="outline"
                    size="sm"
                    class=" btn-rounded mb-0 me-3 d-flex align-items-center justify-content-center"
                >
                    Request
                </argon-button>
            </router-link>
            <router-link to="/avatars/add">
                <argon-button
                    color="success"
                    variant="outline"
                    size="sm"
                    class=" btn-rounded mb-0 me-3 d-flex align-items-center justify-content-center"
                >
                    Add New +
                </argon-button>
            </router-link>
        </div>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Avatar
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Email
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Status
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Date Created
                </th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(style, id) in allAvatars.data" :key="id">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div>
                      <img
                        :src="style.image_url"
                        class="avatar avatar-sm me-3"
                        alt="user1"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ style.name }}</h6>
                    </div>
                  </div>
                </td>
                <td>
                  <p class="text-xs text-secondary mb-0" style="max-width: 100px;">{{ style.email }}</p>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="badge badge-sm bg-gradient-success">Online</span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold"
                    >{{ style.updated_at }}</span
                  >
                </td>
                <td class="align-middle text-center">
                  <router-link
                    :to="`/avatars/${style.id}`"
                    class="text-secondary font-weight-bold text-xs me-3"
                    data-toggle="tooltip"
                    data-original-title="Edit user"
                    >Edit</router-link
                  >
                  <a class="text-secondary font-weight-bold text-xs me-3" @click="updateStatus(style.user_id)">Update Status</a>
                  <a class="text-danger text-xs font-weight-bold" @click="removeAvatar(style.name, style.id)">Remove</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  