<template>
  <div class="card">
    <div class="card-header w-100 d-flex align-items-center justify-content-between pb-0 mb-4">
      <h6>All Legacy Requests</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Legacy
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Date Created
              </th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(style, id) in allLegacies.data" :key="id">
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-md">{{ style.title }}</h6>
                  </div>
                </div>
              </td>
              <td class="align-middle text-center text-sm">
                <span class="badge badge-sm " :class="style.status=='released'? 'bg-gradient-success':style.status=='pending'?'bg-gradient-warning':'bg-gradient-danger'">{{ style.status }}</span>
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold"
                  >{{ style.updated_at }}</span
                >
              </td>
              <td class="align-middle text-center">
                <router-link
                  :to="`/legacies/add?legacy_id=${style.id}`"
                  class="text-info font-weight-bold text-xs me-3"
                  data-toggle="tooltip"
                  data-original-title="Edit user"
                  >Create Video Legacy</router-link
                >
                <a class="text-secondary font-weight-bold text-xs me-3" @click="updateStatus(style.user_id)">Update status</a>
                <a class="text-danger text-xs font-weight-bold" @click="removeAvatar(style.name, style.id)">Decline</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script setup>
import Legacy from "@/services/Legacy";
import { ref, onMounted } from "vue";

const allLegacies = ref([]);
onMounted(() => {
    Legacy.getAllLegacy('per_page=100&status=pending').then((res) => {
      allLegacies.value = res.data;
    });
});
</script>
  