import ApiService from "./ApiService"
import ApiFdService from "./ApiFdService";

export default {
    getUser(id) {
        return ApiService.get(`/api/v1/users/${id}`);
    },
    getUsers() {
        return ApiService.get(`/api/v1/users`);
    },
    excelImport(data) {
        return ApiFdService.post(`/api/v1/auth/excel-import`, data);
    }
}