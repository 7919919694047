<script setup>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import LlOverlay from "@/components/LlOverlay.vue";
import { ref, onMounted } from "vue";
import StyleApi from "@/services/Styles";
import Swal from 'sweetalert2';
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();
const id = route.params.id;
const fd = new FormData();
const imgSrc = ref("");
const styleForm = ref({
    name: "",
    description: ""
});
const loading = ref(false);

onMounted(() => {
    if(id) {
        StyleApi.getStyle(id).then((res) => {
            styleForm.value = res.data.data;
            imgSrc.value = res.data.data.image_url;
        })
    }
})

const onImgChage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = ()=>{
        imgSrc.value = reader.result;
        
        // create blob
        // let blob = new Blob([reader.result], {type: file.type});
        // let url = URL.createObjectURL(blob);
        fd.append("image", file);
    };
}

const addNow = () => {
    fd.append("name", styleForm.value.name);
    fd.append("description", styleForm.value.description);
    StyleApi.addStyle(fd).then(()=>{
        Swal.fire({
            title: "Great!",
            icon: "success",
            text: "Your style has been successfully created!"
        });
        setTimeout(()=>{
            router.push('/styles');
        }, 2000);
    });
};

const updateNow = () => {
    fd.append("name", styleForm.value.name);
    fd.append("description", styleForm.value.description || '');
    fd.append("created_at", styleForm.value.created_at);
    fd.append("id", styleForm.value.id);
    fd.append("updated_at", styleForm.value.updated_at);
    console.log(fd.get('file'));
    if (!fd.get("file")) {
        fd.append("image_url", styleForm.value.image_url);
    }
    StyleApi.updateStyle(fd, styleForm.value.id).then(()=>{
        Swal.fire({
            title: "Great!",
            icon: "success",
            text: "Your style has been successfully updated!"
        });
        setTimeout(()=>{
            router.push('/styles');
        }, 2000);
    });
}

const submitNow = () => {
    loading.value = true;
    if (id) {
        updateNow();
    } else {
        addNow();
    }
}


</script>
<template>
    <div class="py-4 container-fluid">
      <LlOverlay :loading="loading">
        <div class="row">
            <div class="col-md-8">
            
            <div class="card">
                <div class="card-header pb-0">
                <div class="d-flex align-items-center">
                    <p class="mb-0">{{ id? 'Edit':'Add' }} Style</p>
                    <argon-button
                        color="success"
                        class="ms-auto"
                        @click="submitNow"
                    >Save</argon-button
                    >
                </div>
                </div>
                <div class="card-body">
                <p class="text-uppercase text-sm">Style Information</p>
                <div class="row">
                    <div class="col-md-12">
                    <label for="example-text-input" class="form-control-label"
                        >Name</label
                    >
                    <argon-input type="text" value="lucky.jesse" v-model="styleForm.name" />
                    </div>
                    <div class="col-md-12">
                        <label for="example-text-input" class="form-control-label"
                        >Description</label>
                    <textarea rows="19" class="form-control" v-model="styleForm.description"></textarea>
                    </div>
                </div>
                
                </div>
            </div>
            </div>
            <div class="col-md-4">
                <div class="card">
                    <div class="card-header">
                        <h6>Style image</h6>
                    </div>
                    <div class="card-body">
                        <div class="w-100 border rounded d-flex align-items-center justify-content-center" style="min-height: 30vh;">
                            <div v-if="imgSrc" class="w-100 text-center">
                                <img :src="imgSrc" class="w-75 mx-auto" />
                                <div v-if="id" class="mt-4">
                                    <button class="btn btn-outline-danger" @click="imgSrc=''">Change image..</button>
                                </div>
                            </div>
                            <div v-else class="text-center">
                                <i class="ni ni-cloud-upload-96 display-3"></i>
                                <div>Click here to start</div>
                                <label class="btn mt-2 w-50 mx-auto btn-info" for="img-style">
                                    Browse...
                                    <input id="img-style" type="file" class="h-0 w-0" @change="onImgChage" />
                                </label>
                                <div class="text-dark text-12 text-black-50">Best size : 1080px x 1080px</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </LlOverlay>
    </div>
</template>
