<script setup>
import ArgonButton from "@/components/ArgonButton.vue";
import StyleApi from "@/services/Styles";
import { ref, onMounted } from "vue";
import Swal from 'sweetalert2';

const allStyles = ref([]);

const loadStyle = (query) => {
  StyleApi.getAllStyles(query).then((res) => {
    allStyles.value = res.data.data;
  })
}

const removeStyle = (name,id) => {
  Swal.fire({
    icon: 'question',
    title: `Remove style`,
    text: `Are you sure to remove the "${name}" style? This action cannot be undone!`,
    confirmButtonText: "Remove",
    showCancelButton: true,
    cancelButtonText: "Cancel",
  }).then((res)=> {
    if (res.isConfirmed) {
      StyleApi.removeStyle(id).then(()=>{
        Swal.fire({
          title: "Nice!",
          icon: "success",
          text: "Your style has been successfully removed!"
        });
        loadStyle();
      })
    }
  })
}



onMounted(() => {
  loadStyle();
})
</script>
<template>
    <div class="card">
      <div class="card-header w-100 d-flex align-items-center justify-content-between pb-0 mb-4">
        <h6>All Styles</h6>
        <router-link to="/styles/add">
            <argon-button
                color="success"
                variant="outline"
                size="sm"
                class=" btn-rounded mb-0 me-3 d-flex align-items-center justify-content-center"
            >
                Add New +
            </argon-button>
        </router-link>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Styles
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Description
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Status
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Date Created
                </th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(style, id) in allStyles.data" :key="id">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div>
                      <img
                        :src="style.image_url"
                        class="avatar avatar-sm me-3"
                        alt="user1"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ style.name }}</h6>
                    </div>
                  </div>
                </td>
                <td>
                  <p class="text-xs text-secondary mb-0 text-truncate" style="max-width: 100px;">{{ style.description }}</p>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="badge badge-sm bg-gradient-success">Online</span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold"
                    >{{ style.updated_at }}</span
                  >
                </td>
                <td class="align-middle text-center">
                  <router-link
                    :to="`/styles/${style.id}`"
                    class="text-secondary font-weight-bold text-xs me-5"
                    data-toggle="tooltip"
                    data-original-title="Edit user"
                    >Edit</router-link
                  >
                  <a class="text-danger text-xs font-weight-bold" @click="removeStyle(style.name, style.id)">Remove</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  