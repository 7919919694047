<script setup>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import LlOverlay from "@/components/LlOverlay.vue";
import { ref, onMounted } from "vue";
import LegacyApi from "@/services/Legacy";
import UserApi from "@/services/User";
import StyleApi from "@/services/Styles";
import Swal from 'sweetalert2';
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();
const id = route.params.id;
const fd = new FormData();
const vidSrc = ref("");
const audSrc = ref("");
const legacyForm = ref({
    title: "",
    message: ""
});

const userData = ref({});
const refData = ref({});
const styleData = ref({});
const loading = ref(false);
let user_id;
let legacy_id;

onMounted(() => {
    legacy_id = route.query.legacy_id;
    if(legacy_id) {
        LegacyApi.getLegacy(legacy_id).then((res) => {
            legacyForm.value = res.data.data;
            user_id = res.data.data.user_id;
            vidSrc.value = res.data.data.image_url;

            if (user_id) {
                UserApi.getUser(user_id).then((res) => {
                    userData.value = res.data.data;

                    if (userData.value.media_asset !== "null") {
                        refData.value = JSON.parse(userData.value.media_asset);
                    }

                    StyleApi.getStyle(userData.value.style_id).then((rp) => {
                        styleData.value = rp.data.data;
                    })
                });
            }
        });
    };


    
})

const onFileChange = (e, type) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = ()=>{
        if (type == 'video') {
            vidSrc.value = reader.result;
            fd.append("video", file);
        } else {
            audSrc.value = reader.result;
            fd.append("audio", file);
        }
        
        // create blob
        // let blob = new Blob([reader.result], {type: file.type});
        // let url = URL.createObjectURL(blob);
        
        
        
    };
}

const addNow = () => {
    fd.append("title", legacyForm.value.title);
    fd.append("message", legacyForm.value.message);
    fd.append("user_id", user_id);
    fd.append("style_id", legacyForm.value.style_id);
    fd.append("avatar_id", legacyForm.value.avatar_id);
    LegacyApi.addLegacy(fd, legacy_id).then(()=>{
        Swal.fire({
            title: "Great!",
            icon: "success",
            text: "Your legacy video has been successfully created!"
        });
        setTimeout(()=>{
            router.push('/legacies');
        }, 2000);
    })
    .catch(() => {
        loading.value = false;
        Swal.fire({
            title: "Error",
            icon: "error",
            text: "An error occured!"
        });
    });
};

const updateNow = () => {
    LegacyApi.updateAvatar(legacyForm.value).then(()=>{
        Swal.fire({
            title: "Great!",
            icon: "success",
            text: "Your avatar has been successfully updated!"
        });
        setTimeout(()=>{
            router.push('/avatars');
        }, 2000);
    });
}

const submitNow = () => {
    loading.value = true;
    if (id) {
        updateNow();
    } else {
        addNow();
    }
}


</script>
<template>
    <div class="py-4 container-fluid">
      <LlOverlay :loading="loading">
        <div class="row">
            <div class="col-md-8">
            
            <div class="card">
                <div class="card-header pb-0">
                    <div class="d-flex align-items-center justify-content-between">
                        
                        <p class="mb-0">{{ id? 'Edit':'Add' }} Legacy</p>
                        <div class="btn-grp">
                            <button v-if="userData.username" class="btn btn-outline-primary mb-0 me-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                View Details
                            </button>
                            <argon-button
                                color="success"
                                class="ms-auto"
                                :disabled="!legacyForm.title || !legacyForm.message || !vidSrc"
                                @click="submitNow"
                            >Save</argon-button
                            >
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div v-if="userData.username">
                        <div class="collapse bg-light p-3 rounded" id="collapseExample">
                            <!-- <p class="text-uppercase text-sm text-bold">Avatar Details</p>
                            <div class="row">
                                <div>Username : {{ userData.username }}</div>
                            </div> -->

                            <p class="text-uppercase text-sm mt-3 text-bold">Legacy Reference</p>
                            <div class="row" v-if="refData">
                                <div class="col-md-12">
                                    <p class="text-xs">Image</p>
                                    <div class="d-flex gap-3">
                                        <img v-for="(img,k) in refData.images" :key="k" :src="img.source" width="130" height="130" class="object-cover" />
                                    </div>
                                </div>
                                <div class="col-md-12 mt-4">
                                    <p class="text-xs">Style</p>
                                    <div class="d-flex gap-3">
                                        <img :src="styleData.image_url" width="130" height="130" class="fit-cover" />
                                    </div>
                                    <div class="mt-2">{{ styleData.name }}</div>
                                </div>
                                <div class="col-md-12 mt-4">
                                    <p class="text-xs">Audio</p>
                                    <div>
                                        <audio class="w-full" controls :src="refData.audio.source">
                                            <source :src="refData.audio.source" type="audio/mpeg">
                                            Your browser does not support the audio element.
                                        </audio>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="text-uppercase text-sm mt-3 text-bold">Legacy Video Information</p>
                    <div class="row">
                        <div class="col-md-12">
                        <label for="example-text-input" class="form-control-label"
                            >Title</label
                        >
                        <argon-input type="text" value="lucky.jesse" v-model="legacyForm.title" />
                        </div>
                        <div class="col-md-12">
                            <label for="example-text-input" class="form-control-label"
                            >Message</label>
                        <textarea rows="19" class="form-control" v-model="legacyForm.message"></textarea>
                        </div>
                    </div>
                    
                </div>
            </div>
            </div>
            <div class="col-md-4">
                <div class="card">
                    <div class="card-header">
                        <h6>Legacy Video</h6>
                    </div>
                    <div class="card-body">
                        <div class="w-100 border rounded d-flex align-items-center justify-content-center" style="min-height: 30vh;">
                            <div v-if="vidSrc" class="w-100 text-center">
                                <video width="400" :src="vidSrc" controls>
                                    <source :src="vidSrc" type="video/mp4">
                                    Your browser does not support HTML video.
                                </video>

                                <div v-if="!id" class="mt-4">
                                    <button class="btn btn-outline-danger" @click="vidSrc=''">Change video..</button>
                                </div>
                            </div>
                            <div v-else class="text-center py-4">
                                <i class="ni ni-cloud-upload-96 display-3"></i>
                                <div>Click here to start</div>
                                <label class="btn mt-2 w-50 mx-auto btn-info" for="img-style">
                                    Browse...
                                    <input id="img-style" type="file" class="h-0 w-0" @change="onFileChange($event, 'video')" />
                                </label>
                                <div class="text-dark text-12 text-black-50">Best size : 1080px x 1080px</div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="card mt-3">
                    <div class="card-header">
                        <h6>Legacy Audio</h6>
                    </div>
                    <div class="card-body">
                        <div class="w-100 border rounded d-flex align-items-center justify-content-center" style="min-height: 30vh;">
                            <div v-if="audSrc" class="w-100 text-center">
                                <audio class="w-full" controls :src="audSrc">
                                    <source :src="audSrc" type="audio/mpeg">
                                    Your browser does not support the audio element.
                                </audio>
                                <div v-if="!id" class="mt-4">
                                    <button class="btn btn-outline-danger" @click="audSrc=''">Change audio..</button>
                                </div>
                            </div>
                            <div v-else class="text-center py-4">
                                <i class="ni ni-cloud-upload-96 display-3"></i>
                                <div>Click here to start</div>
                                <label class="btn mt-2 w-50 mx-auto btn-info" for="audio-style">
                                    Browse...
                                    <input id="audio-style" type="file" class="h-0 w-0" @change="onFileChange($event, 'audio')" />
                                </label>
                                <div class="text-dark text-12 text-black-50">Best size : 1080px x 1080px</div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
      </LlOverlay>
    </div>
</template>
