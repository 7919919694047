<template>
    <div class="ll-overlay">
        <div v-if="loading" class="ll-loading-overlay position-absolute w-100 h-100 d-flex align-items-center justify-content-center bg-light opacity-5">
            <img src="@/assets/img/loading.gif" class="w-25" />
        </div>
        <slot></slot>
    </div>
</template>
<script setup>

const props = defineProps({
    loading: {
        type: Boolean,
        default() {
            return false;
        }
    }
});
console.log(props)
</script>
<style>
.ll-overlay {
    position: relative;
    width: 100%;
    min-height: 40vh;
}

.ll-loading-overlay {
    z-index: 100;
}
</style>

