<script setup>
import Legacy from "@/services/Legacy";
import { ref, onMounted } from "vue";

const allLegacies = ref([]);
onMounted(() => {
    Legacy.getAllLegacy().then((res) => {
      allLegacies.value = res.data;
    });
});
</script>
<template>
    <div class="card">
      <div class="card-header w-100 d-flex align-items-center justify-content-between pb-0 mb-4">
        <h6>All Legacy Videos</h6>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Legacy
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Email
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Date Created
                </th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(style, id) in allLegacies.data" :key="id">
                <td>
                  <h6 class="mb-0 text-sm">{{ style.title }}</h6>
                  <!-- <div class="btn-box mt-2">
                    <button class="btn btn-sm btn-outline-primary me-2">Play Audio</button>
                    <button class="btn btn-sm btn-outline-primary">Play Video</button>
                  </div> -->
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold"
                    >{{ style.email }}</span
                  >
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold"
                    >{{ style.updated_at }}</span
                  >
                </td>
                <td class="align-middle text-center">
                  <router-link
                    :to="`/styles/${style.id}`"
                    class="text-secondary font-weight-bold text-xs me-5"
                    data-toggle="tooltip"
                    data-original-title="Edit user"
                    >Edit</router-link
                  >
                  <a class="text-danger text-xs font-weight-bold">Remove</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
