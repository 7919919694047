import axios from "axios";

const ApiService = axios.create({
  baseURL: process.env.VUE_APP_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
    timeout: 1000,
  },
});

ApiService.interceptors.request.use((config) => {
  var token = localStorage.getItem("ll_token");
  config.headers.Authorization = `Bearer ${token}`
  return config
})

export default ApiService;