import { createStore } from "vuex";
import Auth from "./Auth";
import General from "./General";

const authStore = Auth;
const generalStore = General;

export default createStore({
  modules: {
    Auth: {
      ...authStore,
      namespaced:true
    },
    General: {
      ...generalStore,
      namespaced: true,
    },
  }
})