<template>
    <div class="w-100 d-flex align-items-center justify-content-center" style="height: 100vh;">
        <div>Logging out your account....</div>
    </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { onMounted } from "vue";

const router = useRouter();
const logout = () => {
    localStorage.clear();
}
onMounted(() => {
    logout();
    setTimeout(() => {
        router.push("/signin")
    },3000)
})
</script>
