<script setup>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import Carousel from "./components/Carousel.vue";
import CategoriesList from "./components/CategoriesList.vue";

// API
import LegacyApi from "@/services/Legacy";
import AvatarApi from "@/services/Avatar";
import StyleApi from "@/services/Styles";
import UserApi from "@/services/User";

// REF
const legacy = ref({});
const request = ref({});
const avatar = ref({});
const style = ref({});
const user = ref({});

onMounted(() => {
  LegacyApi.getLegacies().then((res) => {
    legacy.value = res.data;
    AvatarApi.getAvatarRequest("avatar_ready=0").then((rep) => {
      request.value = rep.data;
      AvatarApi.getAvatar("").then((resp) => {
        avatar.value = resp.data;
        StyleApi.getStyles().then((reps) => {
          style.value = reps.data;
          UserApi.getUsers().then((reps) => {
            user.value = reps.data;
            // const today = new Date();
            // const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
            // const diff = user.value.data.data.filter((item) => new Date(item.created_at) > yesterday);
            // console.log(diff.length/(user.value.data.data).length * 100, user.value.data.data);
          });
        });
      })
    })
  });
})

import US from "@/assets/img/icons/flags/US.png";
import DE from "@/assets/img/icons/flags/DE.png";
import GB from "@/assets/img/icons/flags/GB.png";
import BR from "@/assets/img/icons/flags/BR.png";
import { onMounted,ref } from "vue";

const sales = {
  us: {
    country: "United States",
    sales: 2500,
    value: "$230,900",
    bounce: "29.9%",
    flag: US,
  },
  germany: {
    country: "Germany",
    sales: "3.900",
    value: "$440,000",
    bounce: "40.22%",
    flag: DE,
  },
  britain: {
    country: "Great Britain",
    sales: "1.400",
    value: "$190,700",
    bounce: "23.44%",
    flag: GB,
  },
  brasil: {
    country: "Brasil",
    sales: "562",
    value: "$143,960",
    bounce: "32.14%",
    flag: BR,
  },
};
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div v-if="request.data" class="col-lg-3 col-md-6 col-12">
            <router-link to="/avatars/request">
              <mini-statistics-card
                title="New Requests"
                :value="request?.data?.data?.length"
                description="<span
                  class='text-sm font-weight-bolder text-success'
                  >+55%</span> since yesterday"
                :icon="{
                  component: 'ni ni-money-coins',
                  background: 'bg-gradient-primary',
                  shape: 'rounded-circle',
                }"
              />
            </router-link>
          </div>
          <div v-if="avatar.data" class="col-lg-3 col-md-6 col-12">
            <router-link to="/avatars">
              <mini-statistics-card
                title="Total Avatar"
                :value="avatar?.data?.data?.length"
                description="<span
                  class='text-sm font-weight-bolder text-success'
                  >+3%</span> since last week"
                :icon="{
                  component: 'ni ni-world',
                  background: 'bg-gradient-danger',
                  shape: 'rounded-circle',
                }"
              />
            </router-link>
          </div>
          <div v-if="legacy.data" class="col-lg-3 col-md-6 col-12">
            <router-link to="/legacies">
              <mini-statistics-card
                title="Total Legacies"
                :value="`${legacy?.data?.length}`"
                description="<span
                  class='text-sm font-weight-bolder text-danger'
                  >-2%</span> since last quarter"
                :icon="{
                  component: 'ni ni-paper-diploma',
                  background: 'bg-gradient-success',
                  shape: 'rounded-circle',
                }"
              />
            </router-link>
          </div>
          <div v-if="style.data" class="col-lg-3 col-md-6 col-12">
            <router-link to="/styles">
              <mini-statistics-card
                title="Total Styles"
                :value="style?.data?.data?.length"
                description="<span
                  class='text-sm font-weight-bolder text-success'
                  >+5%</span> than last month"
                :icon="{
                  component: 'ni ni-cart',
                  background: 'bg-gradient-warning',
                  shape: 'rounded-circle',
                }"
              />
            </router-link>
          </div>
          <div v-if="user.data" class="col-lg-3 col-md-6 col-12">
            <router-link to="/users">
              <mini-statistics-card
                title="Users"
                :value="user?.data?.data?.length"
                description="<span
                  class='text-sm font-weight-bolder text-success'
                  >+33%</span> since yesterday"
                :icon="{
                  component: 'ni ni-user-run',
                  background: 'bg-gradient-info',
                  shape: 'rounded-circle',
                }"
              />
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-7 mb-lg">
            <!-- line chart -->
            <div class="card z-index-2">
              <gradient-line-chart
                id="chart-line"
                title="Sales Overview"
                description="<i class='fa fa-arrow-up text-success'></i>
      <span class='font-weight-bold'>4% more</span> in 2021"
                :chart="{
                  labels: [
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec',
                  ],
                  datasets: [
                    {
                      label: 'Mobile Apps',
                      data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
                    },
                  ],
                }"
              />
            </div>
          </div>
          <div class="col-lg-5">
            <carousel />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-7 mb-lg-0 mb-4">
            <div class="card">
              <div class="p-3 pb-0 card-header">
                <div class="d-flex justify-content-between">
                  <h6 class="mb-2">Sales by Country</h6>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table align-items-center">
                  <tbody>
                    <tr v-for="(sale, index) in sales" :key="index">
                      <td class="w-30">
                        <div class="px-2 py-1 d-flex align-items-center">
                          <div>
                            <img :src="sale.flag" alt="Country flag" />
                          </div>
                          <div class="ms-4">
                            <p class="mb-0 text-xs font-weight-bold">
                              Country:
                            </p>
                            <h6 class="mb-0 text-sm">{{ sale.country }}</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="text-center">
                          <p class="mb-0 text-xs font-weight-bold">Sales:</p>
                          <h6 class="mb-0 text-sm">{{ sale.sales }}</h6>
                        </div>
                      </td>
                      <td>
                        <div class="text-center">
                          <p class="mb-0 text-xs font-weight-bold">Value:</p>
                          <h6 class="mb-0 text-sm">{{ sale.value }}</h6>
                        </div>
                      </td>
                      <td class="text-sm align-middle">
                        <div class="text-center col">
                          <p class="mb-0 text-xs font-weight-bold">Bounce:</p>
                          <h6 class="mb-0 text-sm">{{ sale.bounce }}</h6>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <categories-list
              :categories="[
                {
                  icon: {
                    component: 'ni ni-mobile-button',
                    background: 'dark',
                  },
                  label: 'Devices',
                  description: '250 in stock <strong>346+ sold</strong>',
                },
                {
                  icon: {
                    component: 'ni ni-tag',
                    background: 'dark',
                  },
                  label: 'Tickets',
                  description: '123 closed <strong>15 open</strong>',
                },
                {
                  icon: { component: 'ni ni-box-2', background: 'dark' },
                  label: 'Error logs',
                  description: '1 is active <strong>40 closed</strong>',
                },
                {
                  icon: { component: 'ni ni-satisfied', background: 'dark' },
                  label: 'Happy Users',
                  description: '+ 430',
                },
              ]"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
