<template>
  <div class="card">
    <div class="card-header w-100 d-flex align-items-center justify-content-between pb-0 mb-4">
      <h6>All Subscribers</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <!-- <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Username
              </th> -->
              <!-- <th
                class="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Last Name
              </th> -->
              <th
                class="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Email
              </th>
              <th
                class="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Plan Name
              </th>
              <th
                class="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Amount
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Start Date
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                End Date
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(style, id) in allUsers.data" :key="id">
              <tr v-if="style.get_lifetime_plan || style.get_premium_plan">
                <td class="align-middle text-start text-sm">
                  <span class="text-secondary text-xs font-weight-bold">{{ style.email }}</span>
                </td>
                <td class="align-middle text-start text-sm">
                  <span class="text-secondary text-xs font-weight-bold">{{ style?.get_premium_plan?.plan_name || 'Lifetime' }}</span>
                </td>
                <td class="align-middle text-start text-sm">
                  <span class="text-secondary text-xs text-uppercase font-weight-bold">{{ style?.get_premium_plan?.amount || style?.get_lifetime_plan?.amount }} {{ style?.get_premium_plan?.currency || style?.get_lifetime_plan?.currency }}</span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold"
                    >{{ style?.get_premium_plan?.current_period_start || style?.get_lifetime_plan?.created_at }}</span
                  >
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold"
                    >{{ style?.get_premium_plan?.current_period_end || 'Lifetime' }}</span
                  >
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script setup>
import User from "@/services/User";
import { ref, onMounted } from "vue";

const allUsers = ref([]);
onMounted(() => {
    User.getUsers().then((res) => {
      allUsers.value = res.data.data;
      console.log(allUsers.value);
    });
});
</script>
<style scoped>
.table thead th{
  padding: 0.75rem 0.5rem;
}
</style>
  