import ApiService from "./ApiService";
import ApiFdService from "./ApiFdService";

export default {
    async getAvatar(data) {
        return ApiService.get(`/api/v1/avatars?${data}`)
    },
    async getAvatarById(id) {
        return ApiService.get(`/api/v1/avatars/${id}`)
    },
    async getAvatarRequest(data) {
        return ApiService.get(`/api/v1/users?${data}`)
    },
    async createAvatar(data) {
        return ApiFdService.post(`/api/v1/avatars`, data)
    },
    async updateAvatar(data) {
        return ApiService.post(`/api/v1/avatars/${data?.id}`, data)
    },
    async removeAvatar(id) {
        return ApiService.delete(`/api/v1/avatars/${id}`)
    },
    async rejectAvatarRequest(data) {
        return ApiFdService.post(`api/v1/avatar_status`, data)
    },
    async updateAvatarStatus(data) {
        return ApiService.post(`/api/v1/avatar_status`, data)
    }
}