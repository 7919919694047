<script>
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";

export default defineComponent({
  components: {
    ArgonInput,
    ArgonSwitch,
    ArgonButton
  },
  data() {
    return {
      dataForm: {
        email:"",
        password:""
      }
    }
  },
  methods: {
    ...mapActions("Auth",["LogIn"]),
    tester() {
      this.LogIn(this.dataForm).then(() => {
        this.$router.push("/dashboard")
      });
    }
  }
})
</script>
<template>
  <div class="container">
    <div
        class=" w-100 d-flex justify-content-center align-items-center"
        style="height: 100vh;"
      >
      <div class="card shadow">
          <div class="pb-0 card-header text-start text-center">
            <img src="@/assets/img/logo-long.png" width="300" class="mt-3 mx-auto" />
            <!-- <h4 class="font-weight-bolder">Sign In</h4> -->
            <p class="mb-0">Enter your email and password to sign in</p>
          </div>
          <div class="card-body">
            <div role="form">
              <div class="mb-3">
                <argon-input
                  id="email"
                  type="email"
                  placeholder="Email"
                  name="email"
                  size="lg"
                  v-model="dataForm.email"
                />
              </div>
              <div class="mb-3">
                <argon-input
                  id="password"
                  type="password"
                  placeholder="Password"
                  name="password"
                  size="lg"
                  v-model="dataForm.password"
                />
              </div>
              <argon-switch id="rememberMe" name="remember-me"
                >Remember me</argon-switch
              >

              <div class="text-center">
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  fullWidth
                  size="lg"
                  @click="tester"
                  >Sign in</argon-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>
