import ApiService from "./ApiService";
import ApiFdService from "./ApiFdService";

export default {
    getAllLegacy(query) {
        return ApiService.get(`/api/v1/legacies?${query}`);
    },
    getLegacy(id) {
        return ApiService.get(`/api/v1/legacies/${id}`)
    },
    getLegacies() {
        return ApiService.get(`/api/v1/legacies`)
    },
    addLegacy(data, id) {
        return ApiFdService.post(`/api/v1/legacies/${id}`, data);
    },
    updateLegacy(data) {
        return ApiService.post(`/api/v1/legacies/${data?.id}`, data);
    },
    removeLegacy(id) {
        return ApiService.delete(`/api/v1/legacies/${id}`)
    }
}