import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Tables from "../views/Tables.vue";
import Billing from "../views/Billing.vue";
import VirtualReality from "../views/VirtualReality.vue";
import RTL from "../views/Rtl.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";
import Logout from "../views/Logout.vue";
import DefaultVue from "../layouts/default.vue";
import StylesList from "../views/Styles/List.vue";
import StyleForm from "../views/Styles/Form.vue";
import LegacyList from "../views/Legacies/List.vue";
import LegacyForm from "../views/Legacies/Form.vue";
import LegacyRequest from "../views/Legacies/Request.vue";
import AvatarList from "../views/Avatars/List.vue";
import AvatarRequest from "../views/Avatars/Request.vue";
import AvatarForm from "../views/Avatars/Form.vue";
import UserList from "../views/Users/List.vue";
import SubscriberList from "../views/Subscribers/List.vue";

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/dashboard",
  },
  {
    path: "/",
    name: "user-dashboard",
    component: DefaultVue,
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "/styles",
        name: "All Styles",
        component: StylesList,
      },
      {
        path: "/styles/add",
        name: "Add Style",
        component: StyleForm,
      },
      {
        path: "/styles/:id",
        name: "Edit Style",
        component: StyleForm,
      },
      {
        path: "/legacies",
        name: "Legacy List",
        component: LegacyList,
      },
      {
        path: "/legacy-request",
        name: "Legacy Request List",
        component: LegacyRequest,
      },
      {
        path: "/legacies/add",
        name: "Legacy Video Add",
        component: LegacyForm,
      },
      {
        path: "/avatars",
        name: "Avatar List",
        component: AvatarList
      },
      {
        path: "/avatars/request",
        name: "Avatar Request List",
        component: AvatarRequest
      },
      {
        path: "/avatars/add",
        name: "Add Avatar",
        component: AvatarForm
      },
      {
        path: "/avatars/:id",
        name: "Edit Avatar",
        component: AvatarForm
      },
      {
        path: "/tables",
        name: "Tables",
        component: Tables,
      },
      {
        path: "/billing",
        name: "Billing",
        component: Billing,
      },
      {
        path: "/virtual-reality",
        name: "Virtual Reality",
        component: VirtualReality,
      },
      {
        path: "/rtl-page",
        name: "RTL",
        component: RTL,
      },
      {
        path: "/profile",
        name: "Profile",
        component: Profile,
      },
      {
        path: "/users",
        name: "User List",
        component: UserList,
      },
      {
        path: "/subscribers",
        name: "Subscriber List",
        component: SubscriberList,
      },
    ]
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
