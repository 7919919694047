<script setup>
import AvatarApi from "@/services/Avatar";
import { ref, onMounted } from "vue";
import Swal from 'sweetalert2';

const allAvatars = ref([]);
const listAvatar = ref([]);

const loadAvatar = (query) => {
    AvatarApi.getAvatarRequest(query).then((res) => {
    allAvatars.value = res.data.data;
    listAvatar.value = allAvatars.value.data.filter((a) => a.media_asset !== 'null' && a.avatar_url == null).sort(
      (a,b) => {
        const aCreatedAt = new Date(a.created_at);
        const bCreatedAt = new Date(b.created_at);
        if (aCreatedAt.getTime() > bCreatedAt.getTime()) {
          return -1;
        }
        if (aCreatedAt.getTime() < bCreatedAt.getTime()) {
          return 1;
        }
        return 0;
      }
    );
  })
}

const declineAvatar = (data) => {
  Swal.fire({
    icon: 'question',
    title: `Decline avatar request`,
    text: `Are you sure to decline request from ${data.username}? This action cannot be undone!`,
    confirmButtonText: "Remove",
    showCancelButton: true,
    cancelButtonText: "Cancel",
  }).then((res)=> {
    if (res.isConfirmed) {
      const { value: text } = Swal.fire({
        input: "textarea",
        inputLabel: "Reason of declining",
        inputPlaceholder: "Type your reason here...",
        inputAttributes: {
          "aria-label": "Type your reason here"
        },
        inputValidator: (value) => {
          if (!value) {
            return "You need to type a reason of declining here!";
          }
        },
        showCancelButton: true
      }).then((rs) => {
        // const decObj = {
        //   user_id: data.user_id,
        //   status: 'rejected',
        //   reason: res.value
        // }
        if (rs.isConfirmed) {
          const fd = new FormData();
          fd.append("user_id", data.id);
          fd.append("avatar_status","rejected");
          fd.append("reason", rs.value);
          AvatarApi.rejectAvatarRequest(fd).then(()=>{
            Swal.fire({
              title: "Nice!",
              icon: "success",
              text: "Avatar request has been successfully declined!"
            });
            loadAvatar();
          })
        }
      });
      if (text) {
        Swal.fire(text);
      }
    }
  })
}

// const updateStatus = async (user_id) => {
//     const inputOptions = new Promise((resolve) => {
//         resolve({
//             0: "On progress",
//             1: "Ready"
//         });
//     });
//     const { value: status } = await Swal.fire({
//         title: "Select status",
//         input: "radio",
//         inputOptions,
//         inputValidator: (value) => {
//             if (!value) {
//             return "Please select 1 status.";
//             }
//         }
//     });
//     if (status) {
//         const objData = {
//             user_id,
//             is_avatar_ready: status
//         }
//         AvatarApi.updateAvatarStatus(objData);
//     }
// }

onMounted(() => {
  const query = `avatar_status=pending`;
  loadAvatar(query);
})
</script>
<template>
    <div class="card">
      <div class="card-header w-100 d-flex align-items-center justify-content-between pb-0 mb-4">
        <h6>All Legacy Requests</h6>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Email
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Status (Readiness & Request status)
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Date Created
                </th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(style, id) in listAvatar" :key="id">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-md">{{ style.email }}</h6>
                      <!-- <div class="text-sm">{{ style.first_name }} {{ style.last_name }}</div> -->
                    </div>
                  </div>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="badge badge-sm me-2" :class="style.avatar_status=='released'? 'bg-gradient-success':style.avatar_status=='pending'?'bg-gradient-warning':'bg-gradient-danger'">{{ style.avatar_status=='released'? 'Released': style.avatar_status=='pending'?'Pending':'Rejected' }}</span>
                  <span class="badge badge-sm" :class="style.media_asset==null || style.media_asset == 'null'? 'bg-gradient-danger':'bg-gradient-success'">{{ style.media_asset==null || style.media_asset == 'null'? 'Assets is not ready yet':'Assets is ready' }}</span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold"
                    >{{ style.updated_at }}</span
                  >
                </td>
                <td class="align-middle text-center">
                  <router-link
                    :to="`/avatars/add?user_id=${style.id}`"
                    class="text-info font-weight-bold text-xs me-3"
                    data-toggle="tooltip"
                    data-original-title="Edit user"
                    v-if="!style.avatar_url && style.media_asset != null"
                    >Create Avatar</router-link
                  >
                  <!-- <a class="text-secondary font-weight-bold text-xs me-3" @click="updateStatus(style.id)">Update status</a> -->
                  <a class="text-danger text-xs font-weight-bold" @click="declineAvatar(style)">Decline</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  