<template>
<LlOverlay :loading="loading">
  <div class="card">
    <div class="card-header w-100 d-flex align-items-center justify-content-between pb-0 mb-4">
      <h6>All Users</h6>
      <div class="d-flex justify-content-end">
        <input type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" 
          class="h-0 w-0 hidden" id="upload-img" @change="getFile"/>
         <!-- <label for="upload-img" class="d-flex justify-content-end"> -->
          <argon-button
              color="info"
              variant="outline"
              size="sm"
              class=" btn-rounded mb-0 me-3 d-flex align-items-center justify-content-center"
              @click="uploadFile"
          >
              Browse
          </argon-button>
         <!-- </label> -->
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <!-- <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Username
              </th> -->
              <th
                class="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                First Name
              </th>
              <!-- <th
                class="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Last Name
              </th> -->
              <th
                class="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Email
              </th>
              <th
                class="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Method of Signup
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Date Created
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(style, id) in allUsers.data" :key="id">
              <!-- <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-md">{{ style.username }}</h6>
                  </div>
                </div>
              </td> -->
              <td class="align-middle text-start text-sm">
                <span class="text-secondary text-xs font-weight-bold">{{ style.first_name }}</span>
              </td>
              <!-- <td class="align-middle text-start text-sm">
                <span class="text-secondary text-xs font-weight-bold">{{ style.last_name }}</span>
              </td> -->
              <td class="align-middle text-start text-sm">
                <span class="text-secondary text-xs font-weight-bold">{{ style.email }}</span>
              </td>
              <td class="align-middle text-start text-sm">
                <span class="text-secondary text-xs font-weight-bold">{{ style.signup_type }}</span>
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold"
                  >{{ style.created_at }}</span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</LlOverlay>
</template>
<script setup>
import ArgonButton from "@/components/ArgonButton.vue";
import LlOverlay from "@/components/LlOverlay.vue";
import User from "@/services/User";
import { ref, onMounted } from "vue";
import Swal from 'sweetalert2';

const allUsers = ref([]);
const loading = ref(false);
onMounted(() => {
  getUsers();
});
const getUsers = () => {
  User.getUsers().then((res) => {
    allUsers.value = res.data.data;
  });
}
const getFile = async (e) => {
  loading.value = true;
  const file = e.target.files[0];
  let fd = new FormData();
  fd.append("ExcelFile", file);
  const res = await User.excelImport(fd);
  console.log(res);
  if(res.data.success) {
    Swal.fire({
      title: "Great!",
      icon: "success",
      text: res.data.message
    });
    getUsers();
  } else {
    Swal.fire({
      title: "Error",
      icon: "error",
      text: "An error occured!"
    });
  }
  loading.value = false;
  setTimeout(() => {
    loading.value = false;
  }, 5000);

}
const uploadFile = () => {
  document.getElementById("upload-img").click();
}
</script>
<style scoped>
.table thead th{
  padding: 0.75rem 0.5rem;
}
</style>
  