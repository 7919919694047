import AuthApi from "@/services/Auth";

export default ({
    state: {
        token:""
    },
    mutations:{
        setToken(data, value) {
            data.token = value
        }
    },
    actions: {
        LogIn({commit},data) {
            AuthApi.login(data).then((res) => {
                commit("setToken", res.data.data.token);
                localStorage.setItem("ll_token", res.data.data.token);
                return res;
            });
        }
    }
});